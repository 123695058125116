import axiosInstance from "../../../utils/axiosInstance";

export const getCitythunk = async(url,data,thunkAPI)=>{
    try {
        const resp = await axiosInstance.get(url,data);
        return resp.data?.data;
        
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response)
        
    }
}
export const getCountrythunk = async(url,data,thunkAPI)=>{
    try {
        const resp = await axiosInstance.get(url,data);
        return resp.data?.data;
        
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response)
        
    }
}

export const getScopethunk = async(url,data,thunkAPI)=>{
    try {
        const resp = await axiosInstance.get(url,data);
        return resp.data?.data;
        
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response)
        
    }
}

export const addClientthunk = async(url,data,thunkAPI)=>{
    try {
        const resp = await axiosInstance.post(url,data);
        return resp.data;
        
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response)
        
    }
}

export const updateClientthunk = async(url,data,thunkAPI)=>{
    try {
        const resp = await axiosInstance.put(url,data);
        return resp.data;
        
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response)
        
    }
}

export const getPartnerthunk = async(url,data,thunkAPI)=>{
    try {
        const resp = await axiosInstance.get(url,data);
        return resp.data?.data;
        
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response)
        
    }
}

export const updatePartnerthunk = async(url,data,thunkAPI)=>{
    try {
        const resp = await axiosInstance.put(url,data);
        return resp.data;
        
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response)
        
    }
}

export const addPartnerthunk = async(url,data,thunkAPI)=>{
    try {
        const resp = await axiosInstance.post(url,data);
        return resp.data;
        
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response)
        
    }
}

export const getTokenthunk = async (url, data, thunkAPI) => {
    try {
        const resp = await axiosInstance.get(url, data);
        return resp.data?.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response)
    }
}

export const updateTokenthunk = async(url,data,thunkAPI)=>{
    try {
        const resp = await axiosInstance.put(url,data);
        return resp.data;
        
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response)
        
    }
}

export const addTokenthunk = async(url,data,thunkAPI)=>{
    try {
        const resp = await axiosInstance.post(url,data);
        return resp.data;
        
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response)
    }
}


export const apiClientDetailsThunk = async(url,data,thunkAPI)=>{
    try {
        const resp = await axiosInstance.get(url,data);
        return resp.data?.data;
        
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response)
        
    }
}

export const clientApiDetailscreateThunk = async(url,data,thunkAPI)=>{
    try {
        const resp = await axiosInstance.post(url,data);
        return resp.data;
        
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response)
        
    }
}

export const updateClientApiDetailsThunk = async(url,data,thunkAPI)=>{
    try {
        const resp = await axiosInstance.put(url,data);
        return resp.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response)
        
    }
}

export const updateClientApiPasswordThunk = async(url,data,thunkAPI)=>{
    try {
        const resp = await axiosInstance.put(url,data);
        return resp.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response)
        
    }
}



