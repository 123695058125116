import React, { useEffect, useState } from 'react'
import TableComponent from './TableComponent'
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from 'context';
import { useDispatch, useSelector } from 'react-redux';
import { getToken, addToken, updateToken } from 'store/slice/admin/adminSlice';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from '@mui/icons-material/Edit';
import { Close } from "@mui/icons-material";
import { toast } from 'react-toastify';

const TokenTable = ({ clientData }) => {
    const [controller] = useMaterialUIController();
    const { sidenavColor } = controller;
    const dispatch = useDispatch();
    const [row, setRow] = useState([]);
    const [formtype, setFormType] = useState('');
    const tokenDetails = useSelector((state) => state && state?.admin && state?.admin?.token);
    console.log(tokenDetails)

    useEffect(() => {
        dispatch(getToken())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formtype]);

    const initialState = {
        CLIENT_ID: `${clientData?.CLIENT_ID}`,
        ADDITIONAL_NOTES: '',
        CREATED_BY: '',
        ENTRY_DATE_TIME: '',
        PAYMENT_DETAILS: '',
        PO_NUMBER: '',
        PROMO_TOKENS: '',
        PURCHASED_TOKENS: '',
        TOKEN_DETAILS_ID: '',
        TOKEN_EXPIRY_DATE: '',
        TOKEN_PRICE: '',
        TOKEN_PURCHASE_STATUS: '',
        TOTAL_SOLD_TOKENS: '',
        VALID_FROM: '',
        AMOUNT: ''
    }

    const [tokenInfodetails, setTokenInfodetails] = useState(initialState);

    const inputChangeHandle = e => {
        const { name, value } = e.target
        setTokenInfodetails({ ...tokenInfodetails, [name]: value })
    }

    const register = e => {
        e.preventDefault();
        const data = {
            CLIENT_ID: tokenInfodetails.CLIENT_ID,
            ADDITIONAL_NOTES: tokenInfodetails.ADDITIONAL_NOTES,
            CREATED_BY: tokenInfodetails.CREATED_BY,
            PAYMENT_DETAILS: tokenInfodetails.PAYMENT_DETAILS,
            ENTRY_DATE_TIME: tokenInfodetails.ENTRY_DATE_TIME,
            PO_NUMBER: tokenInfodetails.PO_NUMBER,
            PROMO_TOKENS: tokenInfodetails.PROMO_TOKENS,
            PURCHASED_TOKENS: tokenInfodetails.PURCHASED_TOKENS,
            TOKEN_DETAILS_ID: tokenInfodetails.TOKEN_DETAILS_ID,
            TOKEN_EXPIRY_DATE: tokenInfodetails.TOKEN_EXPIRY_DATE,
            TOKEN_PRICE: tokenInfodetails.TOKEN_PRICE,
            TOKEN_PURCHASE_STATUS: tokenInfodetails.TOKEN_PURCHASE_STATUS,
            TOTAL_SOLD_TOKENS: tokenInfodetails.TOTAL_SOLD_TOKENS,
            VALID_FROM: tokenInfodetails.VALID_FROM,
            AMOUNT: tokenInfodetails.AMOUNT
        }
        validateRegister(data);
    }

    const validateRegister = values => {
        if (!values.CREATED_BY) {
            toast.error('Created by is empty');
            return false;
        } else if (!values.PAYMENT_DETAILS) {
            toast.error('Payment details is empty');
            return false;
        }
        else if (!values.ENTRY_DATE_TIME) {
            toast.error('Date is empty');
            return false;
        } else if (!values.PROMO_TOKENS) {
            toast.error('Promo tokens is empty');
            return false;
        } else if (!values.PURCHASED_TOKENS) {
            toast.error('Purchased token is empty');
            return false;
        } else if (!values.TOKEN_DETAILS_ID) {
            toast.error('Token details is empty');
            return false;
        } else if (!values.TOKEN_EXPIRY_DATE) {
            toast.error('Expiry date is empty');
            return false;
        } else if (!values.TOKEN_PRICE) {
            toast.error('Token Price is empty');
            return false;
        } else if (!values.TOKEN_PURCHASE_STATUS) {
            toast.error('Token Status is empty');
            return false;
        } else if (!values.TOTAL_SOLD_TOKENS) {
            toast.error('Token Sold is empty');
            return false;
        } else if (!values.VALID_FROM) {
            toast.error('Valid From is empty');
            return false;
        } else if(!values.AMOUNT) {
            toast.error('Amount is empty');
            return false;
        } else {
            if (formtype === 'add') {
                dispatch(addToken(values))
            } else if (formtype === 'edit') {
                dispatch(updateToken(values))
            }
        }
    }

    useEffect(() => {
        if (tokenDetails?.length !== 0) {
            const datas = [];
            
            tokenDetails && tokenDetails?.filter((data) => {
                return data.client_id === clientData.CLIENT_ID}).forEach((data, index) => {datas.push({
                    id: index + 1,
                    CLIENT_ID: data.client_id,
                    ADDITIONAL_NOTES: data.additional_notes,
                    CREATED_BY: data.created_by,
                    ENTRY_DATE_TIME: data.entry_date_time,
                    PAYMENT_DETAILS: data.payment_details,
                    PO_NUMBER: data.po_number,
                    PROMO_TOKENS: data.promo_tokens,
                    PURCHASED_TOKENS: data.purchased_tokens,
                    TOKEN_DETAILS_ID: data.token_details_id,
                    TOKEN_EXPIRY_DATE: data.token_expiry_date,
                    TOKEN_PRICE: data.token_price,
                    TOKEN_PURCHASE_STATUS: data.token_purchase_status,
                    TOTAL_SOLD_TOKENS: data.total_sold_tokens,
                    VALID_FROM: data.valid_from,
                    AMOUNT: data.amount
                })
            });
            setRow(datas);
        }
    }, [tokenDetails, clientData?.CLIENT_ID])

    const column = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'CREATED_BY',
            headerName: 'Created By',
            width: 110,
        },
        {
            field: 'TOKEN_DETAILS_ID',
            headerName: 'TokenDetails',
            width: 350,
        },
        {
            field: 'PO_NUMBER',
            headerName: 'PO Number',
            width: 110,
        },
        {
            field: 'TOTAL_SOLD_TOKENS',
            headerName: 'Total Sold',
            width: 110,
        },
        {
            field: 'PURCHASED_TOKENS',
            headerName: 'Tokens',
            width: 110,
        },
        {
            field: 'TOKEN_EXPIRY_DATE',
            headerName: 'Expiry Date',
            width: 250,
        },
        {
            headerName: "Actions",
            width: 110,
            renderCell: (params) => actionElement(params),
        },
    ];

    const handleView = params => {
        setFormType('view');
        setTokenInfodetails(params?.row);
    }

    const handleEdit = params => {
        setFormType('edit');
        setTokenInfodetails(params?.row);
    }

    const handleClose = () => {
        setFormType('');
        setTokenInfodetails(initialState);
    };

    const actionElement = (params) => (
        <div className="action-wrapper">
            <RemoveRedEyeIcon
                onClick={() => handleView(params)}
                className="action-icon"
            />
            <EditIcon
                onClick={() => handleEdit(params)}
                className="action-icon"
            />
        </div>
    );


    return (
        <>
                {
                    (formtype === 'add' || formtype === 'edit' || formtype === 'view') &&
                    <section className="content mb-5">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-header d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
                                            <h3 className="card-title">Add Token</h3>
                                            <Close onClick={handleClose} />
                                        </div>
                                        <form onSubmit={register} >
                                            <div className="card-body">
                                                <div className="row" >
                                                    <div className="col-md-5 col-12">
                                                        <div className="form-group">
                                                            <label for="clientID">Client ID <span class="text-danger">*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="clientID"
                                                                disabled={formtype === 'view'}
                                                                placeholder="Client ID"
                                                                onChange={inputChangeHandle}
                                                                name="CLIENT_ID"
                                                                value={tokenInfodetails.CLIENT_ID}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label for="additionalNotes">Additional Notes </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="additionalNotes"
                                                                placeholder="Additional Notes"
                                                                disabled={formtype === 'view'}
                                                                onChange={inputChangeHandle}
                                                                name="ADDITIONAL_NOTES"
                                                                value={tokenInfodetails.ADDITIONAL_NOTES}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label for="CreatedBy">Created By<span class="text-danger">*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="CreatedBy"
                                                                required={true}
                                                                placeholder="Created By"
                                                                disabled={formtype === 'view'}
                                                                onChange={inputChangeHandle}
                                                                name="CREATED_BY"
                                                                value={tokenInfodetails.CREATED_BY}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label for="date">Date <span class="text-danger">*</span></label>
                                                            <input
                                                                type="text"
                                                                disabled={formtype === 'view'}
                                                                required={true}
                                                                className="form-control"
                                                                id="date"
                                                                placeholder="Date"
                                                                onChange={inputChangeHandle}
                                                                value={tokenInfodetails.ENTRY_DATE_TIME}
                                                                name="ENTRY_DATE_TIME"
                                                            />
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="paymentDetails">Payment Details<span class="text-danger">*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="paymentDetails"
                                                                required={true}
                                                                placeholder="Payment Details"
                                                                disabled={formtype === 'view'}
                                                                onChange={inputChangeHandle}
                                                                name="PAYMENT_DETAILS"
                                                                value={tokenInfodetails.PAYMENT_DETAILS}
                                                            />
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="poNumber">PO Number</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="poNumber"
                                                                placeholder="PO Number"
                                                                disabled={formtype === 'view'}
                                                                onChange={inputChangeHandle}
                                                                name="PO_NUMBER"
                                                                value={tokenInfodetails.PO_NUMBER}
                                                            />
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="promoTokens">Promo Tokens<span class="text-danger">*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="promoTokens"
                                                                required={true}
                                                                placeholder="Promo Tokens"
                                                                disabled={formtype === 'view'}
                                                                onChange={inputChangeHandle}
                                                                name="PROMO_TOKENS"
                                                                value={tokenInfodetails.PROMO_TOKENS}
                                                            />
                                                        </div>
                                                        <div class="form-group">
                                                                <label for="amount">Amount<span class="text-danger">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="amount"
                                                                    required={true}
                                                                    placeholder="Amount"
                                                                    disabled={formtype === 'view'}
                                                                    onChange={inputChangeHandle}
                                                                    name="AMOUNT"
                                                                    value={tokenInfodetails.AMOUNT}
                                                                />
                                                            </div>
                                                    </div>
                                                    <div className="col-md-5 col-12">
                                                            <div class="form-group">
                                                                <label for="purchasedTokens">Purchased Tokens<span class="text-danger">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="purchasedTokens"
                                                                    required={true}
                                                                    placeholder="Purchased Tokens"
                                                                    disabled={formtype === 'view'}
                                                                    onChange={inputChangeHandle}
                                                                    name="PURCHASED_TOKENS"
                                                                    value={tokenInfodetails.PURCHASED_TOKENS}
                                                                />
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="tokenDetails">Token Details<span class="text-danger">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="tokenDetails"
                                                                    required={true}
                                                                    placeholder="Token Details"
                                                                    disabled={formtype === 'view'}
                                                                    onChange={inputChangeHandle}
                                                                    name="TOKEN_DETAILS_ID"
                                                                    value={tokenInfodetails.TOKEN_DETAILS_ID}
                                                                />
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="expiryDate">Expiry Date<span class="text-danger">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="expiryDate"
                                                                    required={true}
                                                                    placeholder="Expiry Date"
                                                                    disabled={formtype === 'view'}
                                                                    onChange={inputChangeHandle}
                                                                    name="TOKEN_EXPIRY_DATE"
                                                                    value={tokenInfodetails.TOKEN_EXPIRY_DATE}
                                                                />
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="tokenPrice">Token Price<span class="text-danger">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="tokenPrice"
                                                                    required={true}
                                                                    placeholder="Token Price"
                                                                    disabled={formtype === 'view'}
                                                                    onChange={inputChangeHandle}
                                                                    name="TOKEN_PRICE"
                                                                    value={tokenInfodetails.TOKEN_PRICE}
                                                                />
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="tokenStatus">Token Status<span class="text-danger">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="tokenStatus"
                                                                    required={true}
                                                                    placeholder="Token Status"
                                                                    disabled={formtype === 'view'}
                                                                    onChange={inputChangeHandle}
                                                                    name="TOKEN_PURCHASE_STATUS"
                                                                    value={tokenInfodetails.TOKEN_PURCHASE_STATUS}
                                                                />
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="totalSold">Total Sold<span class="text-danger">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="totalSold"
                                                                    required={true}
                                                                    placeholder="Total Sold"
                                                                    disabled={formtype === 'view'}
                                                                    onChange={inputChangeHandle}
                                                                    name="TOTAL_SOLD_TOKENS"
                                                                    value={tokenInfodetails.TOTAL_SOLD_TOKENS}
                                                                />
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="validFrom">Valid From<span class="text-danger">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="validFrom"
                                                                    required={true}
                                                                    placeholder="Valid From"
                                                                    disabled={formtype === 'view'}
                                                                    onChange={inputChangeHandle}
                                                                    name="VALID_FROM"
                                                                    value={tokenInfodetails.VALID_FROM}
                                                                />
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button onClick={register} className="btn btn-primary" disabled={formtype === 'view'}>Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                <MDBox p={2} mt="auto" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                    {(formtype !== 'view' && formtype !== 'edit' && formtype !== 'add') &&
                        <MDButton variant="gradient" onClick={() => setFormType('add')}
                            color={sidenavColor}
                        >
                            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                            &nbsp;add token
                        </MDButton>
                    }
                </MDBox>

                {
                    formtype === '' &&
                    <MDBox pt={6} pb={3}>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                    >
                                        <MDTypography variant="h6" color="white">
                                            Token Table
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox pt={3}>
                                        {row && row?.length !== 0 &&
                                            <TableComponent rows={row} columns={column} />
                                        }
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                }
        </>
    )
}

export default TokenTable