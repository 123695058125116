import React from 'react'
import TableComponent from './TableComponent'

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import { useMaterialUIController } from 'context';
import { useDispatch, useSelector } from 'react-redux';

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import axiosInstance from 'utils/axiosInstance';
import moment from "moment";
import { Close } from "@mui/icons-material";
import { toast } from 'react-toastify';
import { addPartner } from 'store/slice/admin/adminSlice';
import { getPartner } from 'store/slice/admin/adminSlice';
import { updatePartner } from 'store/slice/admin/adminSlice';





const PartnerTable = () => {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const dispatch = useDispatch()
  const citydetails = useSelector((state) => state && state?.admin && state?.admin?.city);
  const countrydetails = useSelector((state) => state && state?.admin && state?.admin?.country);
  const scopedetails = useSelector((state) => state && state?.admin && state?.admin?.scope);
  const partnerDetails = useSelector((state) => state && state?.admin && state?.admin?.partner);


  // console.log('country',countrydetails)
  // console.log('citydetails',citydetails)
  // console.log('scopedetails',scopedetails)
  // console.log('partnerDetails', partnerDetails)

  const [formtype, setFormType] = React.useState('');
  const [dates, setDates] = React.useState(moment().format('DD-MM-YYYY hh:mm:ss A'));
  const [country, setCountry] = React.useState();
  const [city, setCity] = React.useState();
  const [filtercity, setFiltercity] = React.useState([]);
  const [partnerstatus, setParentStatus] = React.useState();
  // const [submissionFailed, setSubmissionFailed] = React.useState(false);

  // console.log('city',city)
  // console.log('country',country)

  React.useEffect(() => {
    if (citydetails?.length !== 0 && country) {
      // console.log('filter tester')

      const data = citydetails?.filter((city) => city?.country_id === country)
      setFiltercity(data)

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  const handleSelectChangePartnerStatus = (event) => {
    setParentStatus(event.target.value);
  };

  const handleSelectChangecountry = (event) => {
    setCountry(event.target.value);
  };

  const handleSelectChangeCity = (event) => {
    setCity(event.target.value);
  };

  const handleSelectChangeDate = (event) => {
    setDates(event.target.value);
  };




  React.useEffect(() => {
    // Get the current date using Moment.js
    const currentDate = moment().format('DD-MM-YYYY hh:mm:ss A');

    // Set the current date to the state
    setDates(currentDate);
  }, []);

  React.useEffect(() => {
    dispatch(getPartner())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    dispatch(getPartner());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formtype]);

  const initialState = {
    COMPANY_NAME: '',
    EMAIL: '',
    PHONE: '',
    ADDRESS: '',
    COUNTRY_ID: '',
    ZIP_CODE: '',
    CITY_ID: '',
    REG_DATE: '',
    STATUS: '',
    PARTNER_ID: '',

  }

  const [partnerInfodetails, setPartnerInfodetails] = React.useState(initialState);

  const inputChangeHandle = e => {
    const { name, value } = e.target
    setPartnerInfodetails({ ...partnerInfodetails, [name]: value })
  }

  const register = e => {
    e.preventDefault();
    const data = {
      COMPANY_NAME: partnerInfodetails.COMPANY_NAME,
      EMAIL: partnerInfodetails.EMAIL,
      PHONE: partnerInfodetails.PHONE,
      ADDRESS: partnerInfodetails.ADDRESS,
      ZIP_CODE: partnerInfodetails.ZIP_CODE,
      COUNTRY_ID: country,
      CITY_ID: city,
      STATUS: partnerstatus,
      REG_DATE: dates,
      PARTNER_ID: partnerInfodetails.PARTNER_ID

    }
    validateRegister(data);
    // console.log('clientinnfo',clientInfo)
  }

  const validateRegister = values => {
    const gmailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;

    if (!values.COMPANY_NAME) {
      toast.error('Company field is empty');
      // setSubmissionFailed(true)
      return false;
    } else if (!values.EMAIL) {
      toast.error('email Field  is empty');
      // setSubmissionFailed(true)
      return false;
    }
    else if (!gmailRegex?.test(values.EMAIL)) {
      toast.error('valid email is required');
      // setSubmissionFailed(true)
      return false;
    }
    else if (!values.PHONE) {
      toast.error('Phone Field  is empty');
      return false;
    } else if (!values.ADDRESS) {
      toast.error('Address Field  is empty');
      return false;
    } else if (!values.COUNTRY_ID) {
      toast.error('Country Field  is empty');
      return false;
    } else if (!values.ZIP_CODE) {
      toast.error('Zipcode Field  is empty');
      return false;
    } else if (!values.CITY_ID) {
      toast.error('City Field  is empty');
      return false;
    } else if (!values.STATUS) {
      toast.error('Client Type Field  is empty');
      return false;
    } else if (!values.REG_DATE) {
      toast.error('Reg Date Field  is empty');
      return false;
    } else {
      // console.log('values', values)
      // alert('all field fill ')
      if (formtype === 'add') {
        // dispatch(addClient(values))
        dispatch(addPartner(values))
        // alert('form add')

      } else if (formtype === 'edit') {
        // dispatch(updateClient(values))
        // alert('form edit')
        dispatch(updatePartner(values))
      }
    }

  }

  const [row, setRow] = React.useState([]);

  React.useEffect(() => {
    if (partnerDetails?.length !== 0) {
      const datas = [];
      partnerDetails && partnerDetails.forEach((data, index) => {
        datas.push({
          id: index + 1,
          PARTNER_ID: data.partner_id,
          COMPANY_NAME: data.company_name,
          EMAIL: data.email,
          PHONE: data.phone,
          ADDRESS: data.address,
          COUNTRY_ID: data.country_id,
          ZIP_CODE: data.zip_code,
          CITY_ID: data.city_id,
          REG_DATE: data.reg_date,
          STATUS: data.status,
          CREATE_DATE: data.createdat,
          PARTNER_API_BILING_RATE: data.partner_api_biling_rate,
          COUNTRY: data.country_name,
          CITY_NAME: data.city_name,
          PARTNER_STATUS: data.partner_scope_status,
          // FIRST_NAME: data.first_name,
          // LAST_NAME: data.last_name,
        })
      });
      setRow(datas);
    }


  }, [partnerDetails])


  const column = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'COMPANY_NAME',
      headerName: 'Company',
      // type: 'number',
      width: 110,
      // editable: true,
    },
    {
      field: 'EMAIL',
      headerName: 'Email',
      // type: 'number',
      width: 250,
      // editable: true,
    },
    {
      field: 'COUNTRY',
      headerName: 'Country',
      // type: 'number',
      width: 110,
      // editable: true,
    },
    {
      field: 'CITY_NAME',
      headerName: 'City',
      // type: 'number',
      width: 110,
      // editable: true,
    },
    {
      field: 'PARTNER_STATUS',
      headerName: 'Status',
      // type: 'number',
      width: 120,
      // editable: true,
    },
    {
      field: 'CREATE_DATE',
      headerName: 'Created On',
      // type: 'number',
      width: 250,
      // editable: true,
    },
    {
      headerName: "Actions",
      width: 110,
      renderCell: (params) => actionElement(params),
    },
  ];



  const handleedit = (params) => {
    // alert('this is for edit ');
    setFormType('edit');
    setPartnerInfodetails(params?.row);
    setCountry(params?.row?.COUNTRY_ID);
    setParentStatus(params?.row?.STATUS);
    setCity(params?.row?.CITY_ID);

  };

  const handleView = (params) => {
    setFormType('view')
    setPartnerInfodetails(params?.row)
    setCountry(params?.row?.COUNTRY_ID);
    setParentStatus(params?.row?.STATUS);
    setCity(params?.row?.CITY_ID);
  };

  const handleClose = () => {
    setFormType('');
    setPartnerInfodetails(initialState);
    setCity();
    setCountry();
    setParentStatus();
  };

  const handleDelete = (params) => {
    if (window.confirm("Do You really want to delete blog") === true) {
      axiosInstance
        .delete("main/mycreate-blog?PARTNER_ID=" + params.row.PARTNER_ID)
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message);
            // window.location.reload();
          }
        });
    }
  };

  const actionElement = (params) => (
    <div className="action-wrapper">

      <RemoveRedEyeIcon
        onClick={() => handleView(params)}
        className="action-icon"
      />

      <EditIcon
        onClick={() => handleedit(params)}
        className="action-icon"
      />



      <DeleteIcon style={{ display: 'none' }}
        onClick={() => handleDelete(params)}
        className="action-icon"
      />
    </div>
  );






  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {
          (formtype === 'add' || formtype === 'edit' || formtype === 'view') &&
          <section className="content mb-5">
            <div className="container-fluid">
              <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                  {/* <!-- general form elements --> */}
                  <div className="card card-primary">
                    <div className="card-header d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
                      <h3 className="card-title">Add Partner</h3>
                      <Close onClick={handleClose} />
                    </div>
                    {/* <!-- /.card-header -->
              <!-- form start --> */}
                    <form onSubmit={register} >
                      <div className="card-body">
                        <div className="row" >
                          <div className="col-md-5 col-12">


                            <div className="form-group">
                              <label for="exampleInputPassword1">Company Name <span class="text-danger">*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputPassword1"
                                disabled={formtype === 'view'}
                                // required={true}
                                // required
                                placeholder="Company Name"
                                onChange={inputChangeHandle}
                                name="COMPANY_NAME"
                                value={partnerInfodetails.COMPANY_NAME}
                                required

                              />
                              {/* { submissionFailed &&  formtype !== 'view' && partnerInfodetails.COMPANY_NAME === '' && (
                                <small className="text-danger">Company Name is required</small>
                              )} */}
                            </div>
                            <div className="form-group">
                              <label for="exampleInputPassword1">Partner Email <span class="text-danger">*</span></label>
                              <input
                                type="email"
                                className="form-control"
                                id="exampleInputPassword1"
                                placeholder="Admin Email"
                                // required={true}
                                disabled={formtype === 'view'}
                                onChange={inputChangeHandle}
                                name="EMAIL"
                                value={partnerInfodetails.EMAIL}
                                requiired

                              />
                            </div>
                            <div className="form-group">
                              <label for="exampleInputPassword1">Phone <span class="text-danger">*</span></label>
                              <input
                                type="number"
                                className="form-control"
                                id="exampleInputPassword1"
                                required={true}
                                placeholder="Phone"
                                disabled={formtype === 'view'}
                                onChange={inputChangeHandle}
                                name="PHONE"
                                value={partnerInfodetails.PHONE}
                              />
                            </div>
                            <div class="form-group">
                              <label>Address<span class="text-danger">*</span></label>
                              <textarea
                                class="form-control"
                                required={true}
                                disabled={formtype === 'view'}
                                rows="3" placeholder="Enter ..."
                                onChange={inputChangeHandle}
                                value={partnerInfodetails.ADDRESS}
                                name="ADDRESS"
                              ></textarea>
                            </div>

                          </div>


                          <div className="col-md-5 col-12">

                            <div class="form-group">
                              <label>Country <span class="text-danger">*</span></label>
                              <select class="form-control" onChange={handleSelectChangecountry} value={country} disabled={formtype === 'view'}>
                                <option value=''>Select Option</option>
                                {
                                  countrydetails && countrydetails?.map((data) => {
                                    return (
                                      <option key={data.COUNTRY_ID} value={data.COUNTRY_ID}>{data.COUNRTY_NAME}</option>

                                    )
                                  })
                                }
                              </select>
                            </div>

                            <div class="form-group">
                              <label>City <span class="text-danger">*</span></label>
                              <select class="form-control" onChange={handleSelectChangeCity} value={city} disabled={formtype === 'view'}>
                                <option value=''>Select Option</option>
                                {
                                  citydetails && countrydetails && country && filtercity?.map((city) => {
                                    return (
                                      <option key={city.city_id} value={city.city_id}>{city.city_name}</option>
                                    )
                                  })
                                }
                              </select>
                            </div>

                            <div class="form-group">
                              <label> Partner Status <span class="text-danger">*</span></label>
                              <select class="form-control" onChange={handleSelectChangePartnerStatus} value={partnerstatus} disabled={formtype === 'view'}>
                                <option value=''>select one</option>
                                {
                                  scopedetails?.length !== 0 && scopedetails.filter((data) => data.SCOPE_NAME === 'PARTNER_STATUS').map((data) => {
                                    return (
                                      <option key={data?.SCOPE_ID} value={data?.SCOPE_ID}>{data?.SCOPE_TYPE}</option>
                                    )
                                  })
                                }
                              </select>
                            </div>
                            <div className="form-group">
                              <label for="exampleInputPassword1">Zipcode  <span class="text-danger">*</span></label>
                              <input
                                type="number"
                                className="form-control"
                                disabled={formtype === 'view'}
                                id="exampleInputPassword1"
                                required={true}
                                placeholder="Zipcode"
                                onChange={inputChangeHandle}
                                value={partnerInfodetails.ZIP_CODE}
                                name="ZIP_CODE"
                              />
                            </div>
                            <div className="form-group">
                              <label for="exampleInputPassword1">Reg Date <span class="text-danger">*</span></label>
                              <input
                                type="text"
                                disabled={formtype === 'view'}
                                readOnly
                                required={true}
                                className="form-control"
                                // id="exampleInputPassword1"
                                id="exampleDate"
                                placeholder="Reg Date"
                                onChange={handleSelectChangeDate}
                                // onClick={handleSelectChangeDate}
                                value={dates}
                                name="REG_DATE"
                              />
                            </div>

                          </div>

                        </div>
                      </div>
                      {/* <!-- /.card-body --> */}

                      <div className="card-footer">
                        <button onClick={register} className="btn btn-primary" disabled={formtype === 'view'}>Submit</button>
                      </div>
                    </form>
                  </div>
                  {/* <!-- /.card -->

            <!-- general form elements --> */}

                  {/* <!-- /.card --> */}

                  {/* <!-- Input addon --> */}

                  {/* <!-- /.card -->
            <!-- Horizontal Form --> */}

                  {/* <!-- /.card --> */}

                </div>
                {/* <!--/.col (left) --> */}
                {/* <!-- right column --> */}

                {/* <!--/.col (right) --> */}
              </div>
              {/* <!-- /.row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </section>
        }
        <MDBox p={2} mt="auto" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
          {(formtype !== 'view' && formtype !== 'edit' && formtype !== 'add') &&
            <MDButton variant="gradient" onClick={() => setFormType('add')}
              color={sidenavColor}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp;add partner
            </MDButton>
          }
        </MDBox>

        {
          formtype === '' &&
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Partner Table
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    {row && row?.length !== 0 &&
                      <TableComponent rows={row} columns={column} />
                    }
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>

        }
        <Footer />
      </DashboardLayout>
    </>
  )
}

export default PartnerTable