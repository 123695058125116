import React, { useState } from 'react'
import TableComponent from './TableComponent'

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import { useMaterialUIController } from 'context';
import { useDispatch, useSelector } from 'react-redux';

// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from '@mui/icons-material/Edit';
// import moment from "moment";
import axiosInstance from 'utils/axiosInstance';
import { Close } from "@mui/icons-material";
import { toast } from 'react-toastify';
import { clientApiDetails } from 'store/slice/admin/adminSlice';
import { getCountry } from 'store/slice/admin/adminSlice';

const CountryTable = () => {

    const [formtype, setFormType] = React.useState('');
    const [controller] = useMaterialUIController();
    const { sidenavColor } = controller;
    const dispatch = useDispatch()
    const countrydetails = useSelector((state)=>state && state?.admin && state?.admin?.country)
    // console.log('countrydetails',countrydetails);
    const [rows,setRows]=useState([]);

    // console.log(rows)

    React.useEffect(()=>{
      dispatch(getCountry());
           // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    React.useEffect(()=>{
      if (countrydetails?.length!==0) {
        const datas = [];
        countrydetails && countrydetails.forEach((data,index) => {
          datas.push({
            id:index + 1,
            COUNRTY_NAME:data.COUNRTY_NAME,
            COUNTRY_ID:data.COUNTRY_ID,
            COUNTRY_ISO:data.COUNTRY_ISO,
            CREATED_TIME:data.createdAt,
          })
        });
        setRows(datas)
      }

    },[countrydetails])

    const initialState = {
        COUNTRY_ISO:"",
        COUNRTY_NAME:"",
    }
    const [country,setCountry]= useState(initialState);

    const inputChangeHandle = e => {
        const { name, value } = e.target
        setCountry({ ...country, [name]: value })
      }

    const createCountry = async (e)=>{
        e.preventDefault();

        await axiosInstance.post('/admin/country',country).then((res)=>{
            if (res.data.status === 1) {
                toast.success('country created')
            }

        }).catch((err)=>{
            // console.log('err',err)
            toast.error('country add failed')
        })
    }



    const column = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
          field: 'COUNRTY_NAME',
          headerName: 'Country name',
          width: 150,
          editable: true,
        },
        {
          field: 'COUNTRY_ISO',
          headerName: 'Iso Code',
          width: 150,
          editable: true,
        }
      ];

    React.useEffect(()=>{
        dispatch(clientApiDetails())
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const handleClose = ()=>{
        setFormType('');
    }
    
    return (
        <>
            <DashboardLayout>
                <DashboardNavbar />
                {
          (formtype === 'add' || formtype === 'edit' || formtype === 'view') &&
          <section className="content mb-5">
            <div className="container-fluid">
              <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                  {/* <!-- general form elements --> */}
                  <div className="card card-primary">
                    <div className="card-header d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
                      <h3 className="card-title">Add Partner</h3>
                      <Close onClick={handleClose} />
                    </div>
                    {/* <!-- /.card-header -->
              <!-- form start --> */}
                    <form onSubmit={createCountry} >
                      <div className="card-body">
                        <div className="col-md-12 d-flex gap-3" >
                          <div className="col-md-6">


                            <div className="form-group">
                              <label for="exampleInputPassword1">Country Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputPassword1"
                                disabled={formtype === 'view'}
                                required={true}
                                placeholder="Company Name"
                                onChange={inputChangeHandle}
                                name="COUNRTY_NAME"
                                value={country.COUNRTY_NAME}

                              />
                            </div>
                           

                          </div>


                          <div className="col-md-6">
                          <div className="form-group">
                              <label for="exampleInputPassword1">Country ISO </label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputPassword1"
                                disabled={formtype === 'view'}
                                required={true}
                                placeholder="Company Name"
                                onChange={inputChangeHandle}
                                name="COUNTRY_ISO"
                                value={country.COUNTRY_ISO}

                              />
                            </div>

                            

                          </div>

                        </div>
                      </div>
                      {/* <!-- /.card-body --> */}

                      <div className="card-footer">
                        <button onClick={createCountry} style={{display:'none'}} className="btn btn-primary" disabled={formtype === 'view'}>Submit</button>
                      </div>
                    </form>
                  </div>
                  {/* <!-- /.card -->

            <!-- general form elements --> */}

                  {/* <!-- /.card --> */}

                  {/* <!-- Input addon --> */}

                  {/* <!-- /.card -->
            <!-- Horizontal Form --> */}

                  {/* <!-- /.card --> */}

                </div>
                {/* <!--/.col (left) --> */}
                {/* <!-- right column --> */}

                {/* <!--/.col (right) --> */}
              </div>
              {/* <!-- /.row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </section>
        }
                <MDBox p={2} mt="auto" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                    {(formtype !== 'view' && formtype !== 'edit' && formtype !== 'add') &&
                        <MDButton variant="gradient" disabled onClick={() => setFormType('add')}
                            color={sidenavColor}
                        >
                            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                            &nbsp;add Country
                        </MDButton>
                    }
                </MDBox>
                {
                    formtype==='' &&
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Country Table
                                    </MDTypography>
                                </MDBox>
                                <MDBox pt={3}>
                                    {rows && rows?.length !== 0 &&
                                        <TableComponent rows={rows} columns={column} />
                                    }
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
                }
                <Footer />

            </DashboardLayout>
        </>
    )
}

export default CountryTable