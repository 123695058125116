import React, { useState } from 'react'
import TableComponent from './TableComponent'

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import { useMaterialUIController } from 'context';
import { useDispatch, useSelector } from 'react-redux';

// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from '@mui/icons-material/Edit';
// import axiosInstance from 'utils/axiosInstance';
// import moment from "moment";
// import { Close } from "@mui/icons-material";
// import { toast } from 'react-toastify';
import { clientApiDetails } from 'store/slice/admin/adminSlice';
import { getCity } from 'store/slice/admin/adminSlice';
import { Close } from '@mui/icons-material';
import axiosInstance from 'utils/axiosInstance';
import { toast } from 'react-toastify';
import { getCountry } from 'store/slice/admin/adminSlice';

const CityTable = () => {

    const [formtype, setFormType] = React.useState('');
    const [controller] = useMaterialUIController();
    const { sidenavColor } = controller;
    const dispatch = useDispatch()
    const citydetails = useSelector((state) => state && state?.admin && state?.admin?.city)
    const countrydetails = useSelector((state) => state && state?.admin && state?.admin?.country);
    // console.log('citydetails', citydetails);
    const [rows, setRows] = useState([]);
    const [country, setCountry] = React.useState();

    const initialState = {
        CITY_NAME: '',
        COUNRTY_NAME: '',
        CITY_CODE: ''

    }
    const [citys, setCitys] = React.useState(initialState)

    React.useEffect(() => {
        dispatch(getCity())
        dispatch(clientApiDetails())
        dispatch(getCountry())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (citydetails && citydetails?.length !== 0) {
            const datas = [];
            citydetails && citydetails.forEach((data, index) => {
                datas.push({
                    id: index + 1,
                    CITY_ID: data.city_id,
                    CITY_NAME: data.city_name,
                    COUNTRY_NAME: data.country_name,
                    CITY_CODE: data.city_code,
                    CREATION_TIME: data.createdAt
                })

            });
            setRows(datas);
        }

    }, [citydetails])


    const column = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'CITY_NAME',
            headerName: 'City Name',
            width: 150,
            editable: true,
        },
        {
            field: 'COUNTRY_NAME',
            headerName: 'Country Name',
            width: 150,
            editable: true,
        },
        {
            field: 'CITY_CODE',
            headerName: 'City Code',
            width: 150,
            editable: true,
        }

    ];

    React.useEffect(() => {
        dispatch(clientApiDetails())
        dispatch(getCity())
        dispatch(getCountry())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formtype])
    const handleClose = () => {
        setFormType('');
    }
    const inputChangeHandle = e => {
        const { name, value } = e.target
        setCitys({ ...citys, [name]: value })
    }
    const handleSelectChangecountry = (event) => {
        setCountry(event.target.value);
      };
    const createCity = async (e) => {
        e.preventDefault();
        const citydata = {
            CITY_CODE:citys.CITY_CODE,
            CITY_NAME:citys.CITY_NAME,
            COUNTRY_ID:country
        }

        await axiosInstance.post('/admin/city', citydata).then((res) => {
            // console.log('res',res?.data)
            if (res.data.status === 1) {
                toast.success('city created')
            }

        }).catch((err) => {
            // console.log('err', err)
            toast.error('city add failed')
        })
    }
    return (
        <>
            <DashboardLayout>
                <DashboardNavbar />
                {
                    (formtype === 'add' || formtype === 'edit' || formtype === 'view') &&
                    <section className="content mb-5">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-header d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
                                            <h3 className="card-title">Add City</h3>
                                            <Close onClick={handleClose} />
                                        </div>
                                        <form onSubmit={createCity} >
                                            <div className="card-body">
                                                <div className="row" >
                                                    <div className="col-md-5 col-12">
                                                        <div className="form-group">
                                                            <label for="exampleInputPassword1">City Name <span class="text-danger">*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputPassword1"
                                                                disabled={formtype === 'view'}
                                                                required={true}
                                                                placeholder="City Name"
                                                                onChange={inputChangeHandle}
                                                                name="CITY_NAME"
                                                                value={citys.CITY_NAME}

                                                            />
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Country <span class="text-danger">*</span></label>
                                                            <select class="form-control" onChange={handleSelectChangecountry} value={country} disabled={formtype === 'view'}>
                                                                <option value=''>Select Option</option>
                                                                {
                                                                    countrydetails && countrydetails?.map((data) => {
                                                                        return (
                                                                            <option key={data.COUNTRY_ID} value={data.COUNTRY_ID}>{data.COUNRTY_NAME}</option>

                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5 col-12">
                                                        <div className="form-group">
                                                            <label for="exampleInputPassword1">City Code <span class="text-danger">*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputPassword1"
                                                                disabled={formtype === 'view'}
                                                                required={true}
                                                                placeholder="City Code"
                                                                onChange={inputChangeHandle}
                                                                name="CITY_CODE"
                                                                value={citys.CITY_CODE}

                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button onClick={createCity}  className="btn btn-primary" disabled={formtype === 'view'}>Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                <MDBox p={2} mt="auto" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                    {(formtype !== 'view' && formtype !== 'edit' && formtype !== 'add') &&
                        <MDButton variant="gradient" onClick={() => setFormType('add')}
                            color={sidenavColor}
                        >
                            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                            &nbsp;add City
                        </MDButton>
                    }
                </MDBox>
                {
                    formtype === '' &&
                    <MDBox pt={6} pb={3}>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                    >
                                        <MDTypography variant="h6" color="white">
                                            City Table
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox pt={3}>
                                        {rows && rows?.length !== 0 &&
                                            <TableComponent rows={rows} columns={column} />
                                        }
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                }
                <Footer />

            </DashboardLayout>
        </>
    )
}

export default CityTable