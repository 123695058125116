import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slice/user/userSlice";
import adminSlice from "./slice/admin/adminSlice";

export const store = configureStore({
    reducer:{
        user:userSlice,
        admin:adminSlice

    }
})