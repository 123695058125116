import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { addClientthunk, addPartnerthunk, apiClientDetailsThunk, clientApiDetailscreateThunk, getCountrythunk, getPartnerthunk, getScopethunk, updateClientApiDetailsThunk, updateClientApiPasswordThunk, updateClientthunk, updatePartnerthunk, getTokenthunk, updateTokenthunk, addTokenthunk } from "./adminThunk";

const initialState ={
    isLoading:false,
    city:[],
    country:[],
    scope:[],
    partner:[],
    clientApiDetails:[],
    authToken:null,
    isLoggedIn:false,

}

export const getCountry = createAsyncThunk(
    'clients/Countries',
    async(data,thunkApi)=>{
        return getCountrythunk('/admin/country',data,thunkApi)

    }
)

export const getCity = createAsyncThunk(
    'clients/cities',
    async(data,thunkApi)=>{
        return getCountrythunk('/admin/city',data,thunkApi)

    }
)
export const getScope = createAsyncThunk(
    'clients/scopes',
    async(data,thunkApi)=>{
        return getScopethunk('/admin/scope',data,thunkApi)

    }
)

export const addClient = createAsyncThunk(
    'add/admin/client',
    async(data,thunkApi)=>{
        return addClientthunk('admin/client',data,thunkApi)

    }
)

export const updateClient = createAsyncThunk(
    'update/admin/client',
    async(data,thunkApi)=>{
        return updateClientthunk(`admin/client?CLIENT_ID=${data?.CLIENT_ID}`,data,thunkApi)

    }
)

export const getPartner = createAsyncThunk(
    'get/admin/partner',
    async(data,thunkApi)=>{
        return getPartnerthunk('admin/partner',data,thunkApi)

    }
)

export const updatePartner = createAsyncThunk(
    'update/admin/partner',
    async(data,thunkApi)=>{
        return updatePartnerthunk(`admin/partner?PARTNER_ID=${data?.PARTNER_ID}`,data,thunkApi)

    }
)

export const addPartner = createAsyncThunk(
    'add/admin/partner',
    async(data,thunkApi)=>{
        return addPartnerthunk('admin/partner',data,thunkApi)

    }
)

export const getToken = createAsyncThunk(
    'get/admin/token-details',
    async(data,thunkApi)=>{
        return getTokenthunk('admin/token-details', data, thunkApi)
    }
)

export const updateToken = createAsyncThunk(
    'update/admin/token-details',
    async(data,thunkApi)=>{
        return updateTokenthunk(`admin/token-details?TOKEN_DETAILS_ID=${data?.TOKEN_DETAILS_ID}`, data, thunkApi)
    }
)

export const addToken = createAsyncThunk(
    'add/admin/token-details',
    async(data,thunkApi)=>{
        return addTokenthunk('admin/token-details', data, thunkApi)
    }
)

export const clientApiDetails = createAsyncThunk(
    'get/admin/clientapidetails',
    async(data,thunkApi)=>{
        return apiClientDetailsThunk('admin/client-api-detail',data,thunkApi)

    }
)

export const clientApiCreation = createAsyncThunk(
    'create/admin/clientapidetails',
    async(data,thunkApi)=>{
        return clientApiDetailscreateThunk('admin/client-api-detail',data,thunkApi)

    }
)

export const updateClientApiDetails = createAsyncThunk(
    'update/admin/clientapidetails',
    async(data,thunkApi)=>{
        return updateClientApiDetailsThunk(`admin/client-api-detail?API_ID=${data?.API_ID}`,data,thunkApi)

    }
)

export const updateClientpassword = createAsyncThunk(
    'update/admin/edit-client-password',
    async(data,thunkApi)=>{
        return updateClientApiPasswordThunk(`admin/edit-client-password?API_ID=${data?.API_ID}`,data,thunkApi)

    }
)








const adminSlice = createSlice({
    name:'admin',
    initialState,
    reducers:{
        loginSomething:(state)=>{
            state.isLoading = true;
        },
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getCountry.pending,(state)=>{

        })
        .addCase(getCountry.fulfilled,(state,{payload})=>{
            state.country = payload;
            // console.log('country',payload)
        })
        .addCase(getCountry.rejected,(state,{payload})=>{
            // console.log('country error',payload)
        });
        builder
        .addCase(getCity.pending,(state)=>{

        })
        .addCase(getCity.fulfilled,(state,{payload})=>{
            state.city = payload;
            // console.log('city',payload)
        })
        .addCase(getCity.rejected,(state,{payload})=>{
            // console.log('city error',payload)
        });

        
        builder
        .addCase(getScope.pending,(state)=>{

        })
        .addCase(getScope.fulfilled,(state,{payload})=>{
            state.scope = payload;
            // console.log('city',payload)
        })
        .addCase(getScope.rejected,(state,{payload})=>{
            // console.log('city error',payload)
        });

        builder
        .addCase(addClient.pending,(state)=>{

        })
        .addCase(addClient.fulfilled,(state,{payload})=>{
            // state.scope = payload;
            if(payload.status===1){

                toast.success('client add succefully');
            }else{
                toast.success(payload?.message)
            }
            // console.log('client',payload)
        })
        .addCase(addClient.rejected,(state,{payload})=>{
            // console.log('client add error',payload)
        });

        builder
        .addCase(updateClient.pending,(state)=>{

        })
        .addCase(updateClient.fulfilled,(state,{payload})=>{
            // state.scope = payload;
            if(payload.status===1){

                toast.success('client update succefully');
            }else{
                toast.warn(payload?.message)
            }
            // console.log('client',payload)
        })
        .addCase(updateClient.rejected,(state,{payload})=>{
            // console.log('client update error',payload)
        });

        builder
        .addCase(getPartner.pending,(state)=>{

        })
        .addCase(getPartner.fulfilled,(state,{payload})=>{
            state.partner = payload;
            // console.log('partner',payload)
            if(payload.status===1){

                toast.success('client update succefully');
            }else{
                toast.success(payload?.message)
            }
            // console.log('client',payload)
        })
        .addCase(getPartner.rejected,(state,{payload})=>{
            // console.log('partner update error',payload)
        });


        builder
        .addCase(updatePartner.pending,(state)=>{

        })
        .addCase(updatePartner.fulfilled,(state,{payload})=>{
            // state.scope = payload;
            if(payload.status===1){

                toast.success('partner update succefully');
            }else{
                toast.warn(payload?.message)
            }
            // console.log('client',payload)
        })
        .addCase(updatePartner.rejected,(state,{payload})=>{
            // console.log('partner update error',payload)
        });

        builder
        .addCase(addPartner.pending,(state)=>{

        })
        .addCase(addPartner.fulfilled,(state,{payload})=>{
            // state.scope = payload;
            if(payload.status===1){

                toast.success('partner add succefully');
            }else{
                toast.success(payload?.message)
            }
            // console.log('client',payload)
        })
        .addCase(addPartner.rejected,(state,{payload})=>{
            // console.log('partner add error',payload)
        });

        builder
            .addCase(getToken.pending, (state) => {
            })
            .addCase(getToken.fulfilled, (state, { payload }) => {
                state.token = payload;
                if (payload.status === 1) {
                    toast.success('Token updated succefully');
                } else {
                    toast.success(payload?.message)
                }
            })
            .addCase(getToken.rejected, (state, { payload }) => {
            });

        builder
        .addCase(clientApiDetails.pending,(state)=>{

        })
        .addCase(clientApiDetails.fulfilled,(state,{payload})=>{
            state.clientApiDetails = payload;
            // console.log('client',payload)
        })
        .addCase(clientApiDetails.rejected,(state,{payload})=>{
            // console.log('partner update error',payload)
        });

        builder
        .addCase(clientApiCreation.pending,(state)=>{

        })
        .addCase(clientApiCreation.fulfilled,(state,{payload})=>{
            // state.clientApiDetails = payload;
            if(payload.status===1){

                toast.success('client add succefully');
            }else{
                toast.success(payload?.message)
            }
            // console.log('client',payload)
        })
        .addCase(clientApiCreation.rejected,(state,{payload})=>{
            // console.log('partner update error',payload)
        });
        builder
        .addCase(updateClientApiDetails.pending,(state)=>{

        })
        .addCase(updateClientApiDetails.fulfilled,(state,{payload})=>{
            // state.clientApiDetails = payload;
            // console.log('client',payload)
            if(payload.status===1){
                toast.success('client api update succefully');
            }else{
                toast.success(payload?.message)
            }
        })
        .addCase(updateClientApiDetails.rejected,(state,{payload})=>{
            // console.log('partner update error',payload)
        });
        builder
        .addCase(updateClientpassword.pending,(state)=>{

        })
        .addCase(updateClientpassword.fulfilled,(state,{payload})=>{
            // state.clientApiDetails = payload;
            // console.log('client',payload)
            if(payload.status===1){
                toast.success('client api update succefully');
            }else{
                toast.success(payload?.message)
            }
        })
        .addCase(updateClientpassword.rejected,(state,{payload})=>{
            // console.log('partner update error',payload)
        });


    }


})


export const {loginSomething}= adminSlice.actions;
export default adminSlice.reducer;


