import React, { useState } from 'react'
import TableComponent from './TableComponent'

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import { useMaterialUIController } from 'context';
import { useDispatch, useSelector } from 'react-redux';

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import axiosInstance from 'utils/axiosInstance';
import moment from "moment";
import { Close } from "@mui/icons-material";
import { toast } from 'react-toastify';
import { clientApiDetails } from 'store/slice/admin/adminSlice';
import { clientApiCreation } from 'store/slice/admin/adminSlice';
import { updateClientApiDetails } from 'store/slice/admin/adminSlice';
import { updateClientpassword } from 'store/slice/admin/adminSlice';

const ClientApiTable = ({ clientData }) => {

  const [formstype, setformstype] = React.useState('');
  const [api_status, setApi_status] = React.useState();
  const [rows, setRows] = useState([]);

  // console.log('rows', rows)
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const dispatch = useDispatch()
  const client_api_details = useSelector((state) => state && state?.admin && state?.admin?.clientApiDetails);
  const scopedetails = useSelector((state) => state && state?.admin && state?.admin?.scope);
  // console.log('client_api_details', client_api_details);
  // console.log('clientData', clientData);
  // console.log('formstype', formstype)
  const [dates, setDates] = React.useState(moment().format('DD-MM-YYYY hh:mm:ss A'));
  const [expairydate, setExpairyDate] = React.useState();
  const [newpassword,setnewPassword]=React.useState('');

  React.useEffect(() => {
    dispatch(clientApiDetails())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    dispatch(clientApiDetails())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formstype])

  const handleSelectChangeClient = (e) => {
    setApi_status(e.target.value)
  }

  const handleChangePassword = (e)=>{
    setnewPassword(e.target.value)
  }

  const handleSelectChangeDate = (e) => {
    setDates(e.target.value)
  }

  const handleSelectExpairy = (e) => {
    setExpairyDate(e.target.value)
  }

  const initialState = {
    CLIENT_ID: `${clientData?.CLIENT_ID}`,
    API_USERNAME: '',
    PASSWORD: '',
    API_KEY_STATUS: '',
    DEPARTMENT_NAME: '',
    X_API_KEY_CREATION_TIME: '',
    X_API_KEY_EXPIRY_TIME: ''
  }
  const [clietApi, setClientApi] = useState(initialState);


  // console.log('clietapi',clietApi)
  // console.log('expairy',expairydate)
  // 2023-12-21T19:15

  React.useEffect(() => {
    // Get the current date using Moment.js
    // const currentDate = moment().format('YYYY-MM-DD');
    const currentDate = moment().format('DD-MM-YYYY hh:mm:ss A');


    // Set the current date to the state
    setDates(currentDate);
  }, []);



  const inputChangeHandle = e => {
    const { name, value } = e.target
    setClientApi({ ...clietApi, [name]: value })
  }

  const register = e => {
    e.preventDefault();
    const data = {
      CLIENT_ID: clietApi.CLIENT_ID,
      API_USERNAME: clietApi.API_USERNAME,
      PASSWORD: clietApi.PASSWORD,
      API_KEY_STATUS: api_status,
      DEPARTMENT_NAME: clietApi.DEPARTMENT_NAME,
      X_API_KEY_CREATION_TIME: new Date(dates.replace(/(\d{2})-(\d{2})-(\d{4})/, '$3-$2-$1')).toISOString().replace(/T/, ' ').replace(/\..+/, ''),
      X_API_KEY_EXPIRY_TIME:  moment(expairydate).format("YYYY-MM-DD hh:mm:ss A")
    }
    // console.log('data', data)
    validateRegister(data);
    // console.log('clientinnfo',clientInfo)
  }

  const validateRegister = values => {

    // const gmailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
    if(formstype === 'password'){
      if (!newpassword) {
        toast.error('Password is empty');
        return false;
      }else{

        const datas = {
          CLIENT_ID:clietApi.CLIENT_ID,
          API_ID:clietApi.API_ID,
          PASSWORD: newpassword,
        }
        dispatch(updateClientpassword(datas))

      }


    }else if (!values.API_USERNAME) {
      toast.error('Api UserName is empty');
      return false;
    } else if (!values.API_KEY_STATUS) {
      toast.error('Api Key Status is empty');
      return false;
    } else if (!values.DEPARTMENT_NAME) {
      toast.error('department name  is empty');
      return false;
    }
    else if (!values.X_API_KEY_CREATION_TIME) {
      toast.error('Api Key Creation field is empty');
      return false;
    } else if (!values.X_API_KEY_EXPIRY_TIME) {
      toast.error('Api Expiry Field  is empty');
      return false;
    } else {
      // console.log('values',values)
      // alert('all field fill ')
      if (formstype === 'add') {
        // dispatch(addClient(values))
        // alert('add')
        if (!values.PASSWORD) {
          toast.error('Password is empty');
          return false;
        }else{

          dispatch(clientApiCreation(values))
          setformstype('view')
        }
      } else if (formstype === 'edit') {
        // alert('edit')
        const datas = {
          CLIENT_ID: clietApi.CLIENT_ID,
          API_USERNAME: clietApi.API_USERNAME,
          API_ID:clietApi.API_ID,
          API_KEY_STATUS: api_status,
          DEPARTMENT_NAME: clietApi.DEPARTMENT_NAME,
          X_API_KEY_CREATION_TIME: new Date(dates.replace(/(\d{2})-(\d{2})-(\d{4})/, '$3-$2-$1')).toISOString().replace(/T/, ' ').replace(/\..+/, ''),
          X_API_KEY_EXPIRY_TIME:  moment(expairydate).format("YYYY-MM-DD hh:mm:ss A")

        }
        // console.log('edit values',datas)
        dispatch(updateClientApiDetails(datas))

        // dispatch(updateClient(values))
      }
    
    }

  }


  const column = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'CLIENT_COMPANY_NAME',
      headerName: 'Client Company',
      width: 150,
    },
    {
      field: 'API_USERNAME',
      headerName: 'Api Username',
      width: 140,
    },
    {
      field: 'X_API_KEY',
      headerName: 'x_api_key',
      width: 350,
    },
    {
      field: 'API_KEY_STATUS',
      headerName: 'API Status',
      width: 110,
    },
    {
      field: 'CLIENT_API_STATUS',
      headerName: 'Client API Status',
      width: 150,
    },
    {
      headerName: "Actions",
      width: 160,
      renderCell: (params) => actionElement(params),
    },


  ];

  // const row = [
  //   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 14 },
  //   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
  //   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
  //   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
  //   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  //   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  //   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  //   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  //   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  // ];


  const handleedit = (params) => {
    // alert('this is for edit ');
    setformstype('edit');
    setClientApi(params?.row)
    setApi_status(params?.row?.API_KEY_STATUS)
    setDates(moment.utc(params?.row?.X_API_KEY_CREATION_TIME).local().format('DD-MM-YYYY hh:mm:ss A'))
    setExpairyDate(moment(params?.row?.X_API_KEY_EXPIRY_TIME).format("YYYY-MM-DDTHH:mm"))
    // setExpairyDate(moment.utc(params?.row?.X_API_KEY_EXPIRY_TIM).local().format('YYYY-MM-DD'))

  };

  const handleView = (params) => {
    setformstype('view')
    setClientApi(params?.row)
    setApi_status(params?.row?.API_KEY_STATUS)
    setDates(moment.utc(params?.row?.X_API_KEY_CREATION_TIME).local().format('DD-MM-YYYY hh:mm:ss A'))
    setExpairyDate(moment(params?.row?.X_API_KEY_EXPIRY_TIME).format("YYYY-MM-DDTHH:mm"))
    // setExpairyDate(moment.utc(params?.row?.X_API_KEY_EXPIRY_TIME).local().format('DD-MM-YYYY'))
    // setExpairyDate(new Date(params?.row?.X_API_KEY_EXPIRY_TIM).toLocaleString())

    // alert('this is for view');
  }

  const handleDelete = (params) => {
    if (window.confirm("Do You really want to delete blog") === true) {
      axiosInstance
        .delete("main/mycreate-blog?CLIENT_ID=" + params.row.CLIENT_ID)
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message);
            // window.location.reload();
          }
        });
    }
  };

  const passwordChange = (params)=>{
    setClientApi(params?.row);
    setformstype('password')
  };

  const actionElement = (params) => (
    <div className="action-wrapper">

      <RemoveRedEyeIcon
        onClick={() => handleView(params)}
        className="action-icon"
      />
      <EditIcon
        onClick={() => handleedit(params)}
        className="action-icon"
      />
      <DeleteIcon style={{display:'none'}}
      // disabled
        onClick={() => handleDelete(params)}
        className="action-icon"
      />
      <KeyIcon
      onClick={()=>passwordChange(params)}
      />
    </div>
  );




  React.useEffect(() => {
    if (client_api_details?.length !== 0) {

      var apidata = [];
      client_api_details?.length!==0 &&  client_api_details?.filter((data) => {
        return data.client_id === clientData.CLIENT_ID

      }).forEach((element, index) => {
        apidata.push({
          id: index + 1,
          API_ID: element.api_id,
          CLIENT_COMPANY_NAME: element.client_company_name,
          DEPARTMENT_NAME: element.department_name,
          API_USERNAME: element.api_username,
          PASSWORD: element.password,
          X_API_KEY: element.x_api_key,
          API_KEY_STATUS: element.api_key_status,
          CLIENT_API_STATUS: element.client_api_status,
          CLIENT_ID: element.client_id,
          X_API_KEY_CREATION_TIME: element.x_api_key_creation_time,
          X_API_KEY_EXPIRY_TIME: element.x_api_key_expiry_time,
          CREATE_AT: element.createdat,
          UPDATE_AT: element.updatedat
        })

      });

      setRows(apidata);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client_api_details,clientData?.CLIENT_ID],formstype)

  

  // React.useEffect(() => {
  //   if (client_api_details && client_api_details?.length !== 0) {
  //     var datas =
  //       client_api_details?.filter((data) => {
  //         return data?.client_id === clientData?.CLIENT_ID

  //       });
  //     if (datas?.length !== 0) {
  //       var apidata = []
  //       datas?.forEach((element, index) => {
  //         apidata.push({
  //           id: index + 1,
  //           API_ID: element.api_id,
  //           CLIENT_COMPANY_NAME: element.client_company_name,
  //           DEPARTMENT_NAME: element.department_name,
  //           API_USERNAME: element.api_username,
  //           PASSWORD: element.password,
  //           X_API_KEY: element.x_api_key,
  //           API_KEY_STATUS: element.api_key_status,
  //           CLIENT_API_STATUS: element.client_api_status,
  //           CLIENT_ID: element.client_id,
  //           X_API_KEY_CREATION_TIME: element.x_api_key_creation_time,
  //           X_API_KEY_EXPIRY_TIME: element.x_api_key_expiry_time,
  //           CREATE_AT: element.createdat,
  //           UPDATE_AT: element.updatedat
  //         })

  //       });
  //       setRows(apidata);
  //     }

  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [clientData?.CLIENT_ID])

  const handleClose = () => {
    setformstype('');
    setClientApi('');
    setApi_status('');
    setExpairyDate('')
    // setDates
    // setClientInfo(initialState);
    // setCity();
    // setCountry();
    // setClientStatus();
    // setClientType();
    // setpartnerid();

  }


  return (
    <>
      {/* <DashboardLayout> */}
      {/* <DashboardNavbar /> */}

      {
        (formstype === 'add' || formstype === 'edit' || formstype === 'view' || formstype ==='password') &&
        <section className="content mb-5">
          <div className="container-fluid">
            <div className="row">
              {/* <!-- left column --> */}
              <div className="col-md-12">
                {/* <!-- general form elements --> */}
                <div className="card card-primary">
                  <div className="card-header d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
                    <h3 className="card-title">Add Client Api</h3>
                    <Close onClick={handleClose} />
                  </div>
                  {/* <!-- /.card-header -->
              <!-- form start --> */}
                  <form onSubmit={register} >
                    <div className="card-body">
                      <div className="col-md-12 d-flex gap-3" >
                        <div className="col-md-5">
                          <div className="form-group" style={{display:formstype==='password'?'none':'block'}}>
                            <label for="exampleInputEmail1">Api User Name <span class="text-danger">*</span></label>
                            <input type="text"
                              className="form-control"
                              id="exampleFormControlInput1"
                              // {formstype === 'view' && readOnly}
                              disabled={formstype === 'view'}
                              // readOnly={formstype ==='view'? true : false}
                              placeholder="UserName"
                              onChange={inputChangeHandle}
                              name="API_USERNAME"
                              value={clietApi.API_USERNAME}

                            />
                          </div>
                          <div className="form-group" style={{display:formstype==='edit'?'none':'block'}}>
                            <label for="exampleInputPassword1">Password <span class="text-danger">*</span></label>
                            <input type="password"
                              className="form-control"
                              id="exampleInputPassword1"
                              placeholder="Password"
                              disabled={formstype === 'view' || formstype ==='password'}
                              onChange={inputChangeHandle}
                              name="PASSWORD"
                              value={clietApi.PASSWORD}
                            />
                          </div>
                          <div className="form-group" style={{display:formstype==='password'?'block':'none'}}>
                            <label for="exampleInputPassword1">New Password <span class="text-danger">*</span></label>
                            <input type="password"
                              className="form-control"
                              id="exampleInputPassword1"
                              placeholder="Password"
                              disabled={formstype === 'view'}
                              onChange={handleChangePassword}
                              name="PASSWORD"
                              value={newpassword}
                            />
                          </div>
                          <div class="form-group" style={{display:formstype==='password'?'none':'block'}}>
                            <label>Client APi Status <span class="text-danger">*</span></label>
                            <select class="form-control" onChange={handleSelectChangeClient} value={api_status} disabled={formstype === 'view'}>
                              <option value=''>select one</option>
                              {
                                scopedetails?.length !== 0 && scopedetails.filter((data) => data.SCOPE_NAME === 'API_KEY_STATUS').map((data) => {
                                  return (
                                    <option key={data?.SCOPE_ID} value={data?.SCOPE_ID}>{data?.SCOPE_TYPE}</option>
                                  )
                                })
                              }


                            </select>
                          </div>

                        </div>


                        <div className="col-md-5">
                          <div className="form-group" style={{display:formstype==='password'?'none':'block'}}>
                            <label for="exampleInputPassword1">Department Name <span class="text-danger">*</span></label>
                            <input type="text"
                              className="form-control"
                              id="exampleFormControlInput2"
                              placeholder="Department"
                              disabled={formstype === 'view'}
                              onChange={inputChangeHandle}
                              name="DEPARTMENT_NAME"
                              value={clietApi.DEPARTMENT_NAME}
                            />
                          </div>





                          <div className="form-group" style={{display:formstype==='password'?'none':'block'}}>
                            <label for="exampleInputPassword1">Creation Date <span class="text-danger">*</span></label>
                            <input
                              type="text"
                              disabled={formstype === 'view'}
                              readOnly
                              className="form-control"
                              // id="exampleInputPassword1"
                              id="exampleDate"
                              placeholder="Reg Date"
                              onChange={handleSelectChangeDate}
                              onClick={handleSelectChangeDate}
                              value={dates}
                              name="X_API_KEY_CREATION_TIME"
                            />
                          </div>
                          <div className="form-group" style={{display:formstype==='password'?'none':'block'}}>
                            <label for="exampleInputPassword1">Expiry Date <span class="text-danger">*</span></label>
                            <input
                              // type={formstype==='view'?"text":'date'}
                              type='datetime-local'
                              disabled={formstype === 'view'}
                              //   readOnly
                              className="form-control"
                              // id="exampleInputPassword1"
                              id="exampleDate1"
                              placeholder="Reg Date"
                              onChange={handleSelectExpairy}
                              onClick={handleSelectExpairy}
                              value={expairydate}
                              name="X_API_KEY_EXPIRY_TIME"
                            />
                          </div>

                        </div>

                      </div>
                    </div>

                    <div className="card-footer">
                      <button onClick={register} className="btn btn-primary" disabled={formstype === 'view'}>Submit</button>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </section>
      }

      <MDBox p={2} mt="auto" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
        {(formstype !== 'view' && formstype !== 'edit' && formstype !== 'add') &&
          <MDButton variant="gradient" onClick={() => setformstype('add')}
            color={sidenavColor}
          >
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;add Client Api
          </MDButton>
        }
      </MDBox>
      {
        formstype === '' && rows?.length !== 0 &&
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Client API Table
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  {/* {row && row?.length !== 0 && */}
                  <TableComponent rows={rows} columns={column} />
                  {/* } */}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      }
      {/* <Footer /> */}

      {/* </DashboardLayout> */}
    </>
  )
}

export default ClientApiTable