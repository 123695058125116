/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { DataGrid } from '@mui/x-data-grid';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
// import DataTable from "examples/Tables/DataTable";

// Data
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
// import { DeleteIcon } from "@mui/icons-material";
import axiosInstance from "utils/axiosInstance";
import { toast } from "react-toastify";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClientDetails } from "store/slice/user/userSlice";
// import { Button, TextField } from "@mui/material";
import {
  useMaterialUIController,
} from "context";

import moment from "moment";
import { Close } from "@mui/icons-material";
import { getCountry } from "store/slice/admin/adminSlice";
import { getCity } from "store/slice/admin/adminSlice";
import { getScope } from "store/slice/admin/adminSlice";
// import MDAlert from "components/MDAlert";
import { addClient } from "store/slice/admin/adminSlice";
import { getPartner } from "store/slice/admin/adminSlice";
import { updateClient } from "store/slice/admin/adminSlice";
import ClientApiTable from "./ClientApiTable";
import TokenTable from "./TokenTable";

function Tables() {
  // const { columns, rows } = authorsTableData();
  // const { columns: pColumns, rows: pRows } = projectsTableData();
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  // const [state, setState] = React.useState();
  const clientdetails = useSelector((state) => state && state?.user && state?.user?.clients);
  const citydetails = useSelector((state)=> state && state?.admin && state?.admin?.city);
  const countrydetails = useSelector((state)=> state && state?.admin && state?.admin?.country);
  const scopedetails = useSelector((state)=> state && state?.admin && state?.admin?.scope);
  const partnerDetails = useSelector((state)=> state && state?.admin && state?.admin?.partner);
  // const [open, setOpen] = React.useState(false);

  // console.log('partnerDetails', partnerDetails)
  // console.log('country',countrydetails);
  // console.log('city',citydetails);
  // console.log('scope',scopedetails);

  const [row, setRows] = React.useState([]);
  // console.log('rows', row)

  const [clientType, setClientType] = React.useState();
  const [country, setCountry] = React.useState();
  const [clientstatus, setClientStatus] = React.useState();
  const [city, setCity] = React.useState();
  const [dates, setDates] = React.useState(moment().format('DD-MM-YYYY hh:mm:ss A'));
  const [filtercity,setFiltercity]=React.useState([]);
  const[partnerid,setpartnerid]= React.useState();
  const[formtype,setFormType]=React.useState('')


  React.useEffect(() => {
    // Get the current date using Moment.js
    // const currentDate = moment().format('YYYY-MM-DD');
    const currentDate = moment().format('DD-MM-YYYY hh:mm:ss A');


    // Set the current date to the state
    setDates(currentDate);
  }, []);


  const handleSelectChangeClient = (event) => {
    setClientType(event.target.value);
  };

  const handleSelectChangecountry = (event) => {
    setCountry(event.target.value);
  };

  const handleSelectChangeClientStatus = (event) => {
    setClientStatus(event.target.value);
  };

  const handleSelectChangeCity = (event) => {
    setCity(event.target.value);
  };
  const handleSelectChangeDate = (event) => {
    setDates(event.target.value);
  };

  const initialState = {
    FIRST_NAME: '',
    LAST_NAME: '',
    COMPANY_NAME: '',
    ADMIN_EMAIL: '',
    PHONE_NUMBER: '',
    ADDRESS: '',
    COUNTRY_ID: '',
    ZIPCODE: '',
    CITY_ID: '',
    CLIENT_TYPE: '',
    REG_DATE: '',
    CLIENT_STATUS: '',
    PARTNER_ID: '',
  }

  const [clientInfo, setClientInfo] = React.useState(initialState);

  const inputChangeHandle = e => {
    const { name, value } = e.target
    setClientInfo({ ...clientInfo, [name]: value })
  }

  const register = e => {
    e.preventDefault();
    const data = {
      CLIENT_ID:clientInfo.CLIENT_ID,
      FIRST_NAME: clientInfo.FIRST_NAME,
      LAST_NAME: clientInfo.LAST_NAME,
      COMPANY_NAME: clientInfo.COMPANY_NAME,
      ADMIN_EMAIL: clientInfo.ADMIN_EMAIL,
      PHONE_NUMBER: clientInfo.PHONE_NUMBER,
      ADDRESS: clientInfo.ADDRESS,
      COUNTRY_ID: country,
      ZIPCODE: clientInfo.ZIPCODE,
      CITY_ID: city,
      CLIENT_TYPE: clientType,
      // REG_DATE: dates,
      REG_DATE:new Date(dates.replace(/(\d{2})-(\d{2})-(\d{4})/, '$3-$2-$1')).toISOString().replace(/T/, ' ').replace(/\..+/, ''),
      CLIENT_STATUS: clientstatus,
      PARTNER_ID: partnerid,

    }
    validateRegister(data);
    // console.log('clientinnfo',clientInfo)
  }

  const validateRegister = values => {

    const gmailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;

    if (!values.FIRST_NAME) {
      toast.error('First Name is empty');
      return false;
    } else if (!values.LAST_NAME) {
      toast.error('Last Name is empty');
      return false;
    } else if (!values.COMPANY_NAME) {
      toast.error('Company field is empty');
      return false;
    } else if (!values.ADMIN_EMAIL ) {
      toast.error('email Field  is empty');
      return false;
    }else if(!gmailRegex.test(values?.ADMIN_EMAIL)){
      toast.error('valid email is required');
      return false;
    } 
    else if (!values.PHONE_NUMBER) {
      toast.error('Phone Field  is empty');
      return false;
    } else if (!values.ADDRESS) {
      toast.error('Address Field  is empty');
      return false;
    } else if (!values.COUNTRY_ID) {
      toast.error('Country Field  is empty');
      return false;
    } else if (!values.ZIPCODE) {
      toast.error('Zipcode Field  is empty');
      return false;
    } else if (!values.CITY_ID) {
      toast.error('City Field  is empty');
      return false;
    } else if (!values.CLIENT_TYPE) {
      toast.error('Client Type Field  is empty');
      return false;
    } else if (!values.REG_DATE) {
      toast.error('Reg Date Field  is empty');
      return false;
    } else if (!values.CLIENT_STATUS) {
      toast.error('Client Status Field  is empty');
      return false;
      
    } else {
      // console.log('values',values)
      // alert('all field fill ')
      if (formtype==='add') {
        dispatch(addClient(values))
        
      }else if(formtype === 'edit'){
        dispatch(updateClient(values))
      }
    }

  }



  React.useEffect(() => {
    dispatch(getClientDetails());
    dispatch(getCountry());
    dispatch(getCity());
    dispatch(getScope());
    dispatch(getPartner());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(()=>{
    dispatch(getClientDetails());
    // setClientInfo(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formtype])

  React.useEffect(() => {
    if (clientdetails?.length !== 0) {
      const datas = [];
      clientdetails && clientdetails.forEach((data, index) => {
        datas.push({
          id: index + 1,
          REG_DATE: data.reg_date,
          CLIENT_ID: data.client_id,
          FIRST_NAME: data.first_name,
          LAST_NAME: data.last_name,
          COMPANY_NAME: data.company_name,
          ADMIN_EMAIL: data.admin_email,
          PHONE_NUMBER: data.phone_number,
          ADDRESS: data.address,
          COUNTRY_ID: data.country_id,
          ZIPCODE: data.zipcode,
          CITY_ID: data.city_id,
          COUNTRY: data.country_name,
          CITY_NAME: data.city_name,
          CLIENT_TYPE: data.scope_client_type,
          CLIENT_STATUS: data.scope_client_status,
          CLIENT_TYPE_ID: data.client_type,
          CLIENT_STATUS_ID: data.client_status,
          PARTNER_ID: data.partner_id,
          PARTNER_COMPANY:data.partner_company_name,
          CLIENT_API_BILING_RATE: data.client_api_biling_rate,
          CREATE_DATE: data.createdat,
        })
      });
      setRows(datas);
    }

  }, [clientdetails])

  const column = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'FIRST_NAME',
      headerName: 'First name',
      width: 150,
      // editable: true,
    },
    {
      field: 'LAST_NAME',
      headerName: 'Last name',
      width: 150,
      // editable: true,
    },
    {
      field: 'COMPANY_NAME',
      headerName: 'Company',
      // type: 'number',
      width: 110,
      // editable: true,
    },
    {
      field: 'CLIENT_TYPE',
      headerName: 'Client Type',
      // type: 'number',
      width: 150,
      // editable: true,
    },
    {
      field: 'CLIENT_STATUS',
      headerName: 'Status',
      // type: 'number',
      width: 110,
      // editable: true,
    },
    {
      field: 'PARTNER_ID',
      headerName: 'Partner Id',
      // type: 'number',
      width: 350,
      // editable: true,
    },
    {
      headerName: "Actions",
      width: 110,
      renderCell: (params) => actionElement(params),
    },
  ];
  const handleedit = (params) => {
    // alert('this is for edit ');
    setFormType('edit');
    setClientInfo(params?.row);
    setClientType(params?.row?.CLIENT_TYPE_ID);
    setCountry(params?.row?.COUNTRY_ID);
    setCity(params?.row?.CITY_ID);
    setClientStatus(params?.row?.CLIENT_STATUS_ID);
    setpartnerid(params?.row?.PARTNER_ID)

  };

  const handleView = (params) => {
    setFormType('view')
    setClientInfo(params?.row);
    setClientType(params?.row?.CLIENT_TYPE_ID);
    setCountry(params?.row?.COUNTRY_ID);
    setCity(params?.row?.CITY_ID);
    setClientStatus(params?.row?.CLIENT_STATUS_ID);
    setpartnerid(params?.row?.PARTNER_ID)

    // alert('this is for view');
  }

  const handleDelete = (params) => {
    if (window.confirm("Do You really want to delete blog") === true) {
      axiosInstance
        .delete("main/mycreate-blog?CLIENT_ID=" + params.row.CLIENT_ID)
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message);
            // window.location.reload();
          }
        });
    }
  };

  const actionElement = (params) => (
    <div className="action-wrapper">

      <RemoveRedEyeIcon
        onClick={() => handleView(params)}
        className="action-icon"
      />

      <EditIcon
        onClick={() => handleedit(params)}
        className="action-icon"
      />



      <DeleteIcon style={{display:'none'}}
        onClick={() => handleDelete(params)}
        className="action-icon"
      />
    </div>
  );


  const handleClose = ()=>{
    setFormType('');
    setClientInfo(initialState);
    setCity();
    setCountry();
    setClientStatus();
    setClientType();
    setpartnerid();

  }

  




  React.useEffect(()=>{
      if (citydetails?.length!==0 && country) {
        // console.log('filter tester')
  
        const data = citydetails?.filter((city)=>city?.country_id === country)
        setFiltercity(data)
        
      }
      
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[country,formtype])



  return (
    <DashboardLayout>
      <DashboardNavbar />
      {
        (formtype==='add' ||formtype==='edit' || formtype === 'view') &&
        <section className="content mb-5">
          <div className="container-fluid">
            <div className="row">
              {/* <!-- left column --> */}
              <div className="col-md-12">
                {/* <!-- general form elements --> */}
                <div className="card card-primary">
                  <div className="card-header d-flex justify-content-between align-items-center" style={{cursor:'pointer'}}>
                    <h3 className="card-title">Add Client</h3>
                    <Close onClick={handleClose}/>
                  </div>
                  {/* <!-- /.card-header -->
              <!-- form start --> */}
                  <form onSubmit={register} >
                    <div className="card-body">
                      <div className="row" >
                        <div className="col-md-5 col-12">
                          <div className="form-group">
                            <label for="exampleInputEmail1">First Name<span class="text-danger">*</span></label>
                            <input type="text"
                              className="form-control"
                              id="exampleInputPassword1"
                              // {formtype === 'view' && readOnly}
                              disabled={formtype ==='view'}
                              // readOnly={formtype ==='view'? true : false}
                              placeholder="First Name"
                              onChange={inputChangeHandle}
                              name="FIRST_NAME"
                              value={clientInfo.FIRST_NAME}

                            />
                          </div>
                          <div className="form-group">
                            <label for="exampleInputPassword1">Last Name <span class="text-danger">*</span></label>
                            <input type="text"
                              className="form-control"
                              id="exampleInputPassword1"
                              placeholder="Last Name"
                              disabled={formtype ==='view'}
                              onChange={inputChangeHandle}
                              name="LAST_NAME"
                              value={clientInfo.LAST_NAME}
                            />
                          </div>
                          <div className="form-group">
                            <label for="exampleInputPassword1">Company Name <span class="text-danger">*</span></label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputPassword1"
                              disabled={formtype ==='view'}
                              placeholder="Company Name"
                              onChange={inputChangeHandle}
                              name="COMPANY_NAME"
                              value={clientInfo.COMPANY_NAME}

                            />
                          </div>
                          <div className="form-group">
                            <label for="exampleInputPassword1">Admin Email <span class="text-danger">*</span></label>
                            <input
                              type="email"
                              className="form-control"
                              id="exampleInputPassword1"
                              placeholder="Admin Email"
                              disabled={formtype ==='view'}
                              onChange={inputChangeHandle}
                              name="ADMIN_EMAIL"
                              value={clientInfo.ADMIN_EMAIL}

                            />
                          </div>
                          <div className="form-group">
                            <label for="exampleInputPassword1">Phone <span class="text-danger">*</span></label>
                            <input
                              type="number"
                              className="form-control"
                              id="exampleInputPassword1"
                              placeholder="Phone"
                              disabled={formtype ==='view'}
                              onChange={inputChangeHandle}
                              name="PHONE_NUMBER"
                              value={clientInfo.PHONE_NUMBER}
                            />
                          </div>
                          <div class="form-group">
                            <label>Address <span class="text-danger">*</span></label>
                            <textarea
                              class="form-control"
                              disabled={formtype ==='view'}
                              rows="3" placeholder="Enter ..."
                              onChange={inputChangeHandle}
                              value={clientInfo.ADDRESS}
                              name="ADDRESS"
                            ></textarea>
                          </div>
                          <div class="form-group">
                            <label>Client Type <span class="text-danger">*</span></label>
                            <select class="form-control" onChange={handleSelectChangeClient} value={clientType} disabled={formtype ==='view'}>
                              <option value=''>select one</option>
                              {
                                scopedetails?.length!==0 && scopedetails.filter((data)=>data.SCOPE_NAME==='CLIENT_TYPE').map((data)=>{
                                  return(
                                    <option key={data?.SCOPE_ID} value={data?.SCOPE_ID}>{data?.SCOPE_TYPE}</option>
                                  )
                                })
                              }

                              
                            </select>
                          </div>

                        </div>


                        <div className="col-md-5 col-12">

                        <div class="form-group">
                            <label>Country <span class="text-danger">*</span></label>
                            <select class="form-control" onChange={handleSelectChangecountry} value={country} disabled={formtype ==='view'}>
                              <option value=''>Select Option</option>
                              {
                                countrydetails && countrydetails?.map((data)=>{
                                  return(
                                    <option key={data.COUNTRY_ID} value={data.COUNTRY_ID}>{data.COUNRTY_NAME}</option>

                                  )
                                })
                              }
                            </select>
                          </div>

                          <div class="form-group">
                            <label>City <span class="text-danger">*</span></label>
                            <select class="form-control" onChange={handleSelectChangeCity} value={city} disabled={formtype ==='view'}>
                              <option value=''>Select Option</option>
                              {
                                citydetails && countrydetails && country && filtercity?.map((city)=>{
                                  return(
                                    <option key={city.city_id} value={city.city_id}>{city.city_name}</option>
                                  )
                                })
                              }
                              {/* <option value='option2'>option 2</option>
                              <option value='option3'>option 3</option>
                              <option value='option4'>option 4</option>
                              <option value='option5'>option 5</option> */}
                            </select>
                          </div>
                          
                          <div class="form-group">
                            <label>Client Status <span class="text-danger">*</span></label>
                            <select class="form-control" onChange={handleSelectChangeClientStatus} value={clientstatus} disabled={formtype ==='view'}>
                              <option value=''>select one</option>
                              {
                                scopedetails?.length!==0 && scopedetails.filter((data)=>data.SCOPE_NAME==='CLIENT_STATUS').map((data)=>{
                                  return(
                                    <option key={data?.SCOPE_ID} value={data?.SCOPE_ID}>{data?.SCOPE_TYPE}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                          <div className="form-group">
                            <label for="exampleInputPassword1">Zipcode <span class="text-danger">*</span></label>
                            <input
                              type="number"
                              className="form-control"
                              disabled={formtype ==='view'}
                              id="exampleInputPassword1"
                              placeholder="Zipcode"
                              onChange={inputChangeHandle}
                              value={clientInfo.ZIPCODE}
                              name="ZIPCODE"
                            />
                          </div>
                          <div className="form-group">
                            <label for="exampleInputPassword1">Reg Date <span class="text-danger">*</span></label>
                            <input
                              type="text"
                              disabled={formtype ==='view'}
                              readOnly
                              className="form-control"
                              // id="exampleInputPassword1"
                              id="exampleDate"
                              placeholder="Reg Date"
                              onChange={handleSelectChangeDate}
                              onClick={handleSelectChangeDate}
                              value={dates}
                              name="REG_DATE"
                            />
                          </div>
                          <div class="form-group">
                            <label>Partner Id <span class="text-danger">*</span></label>
                            <select class="form-control" onChange={(e)=>setpartnerid(e.target.value)} value={partnerid} disabled={formtype ==='view'}>
                              <option value=''>select one</option>
                              {
                                partnerDetails?.length!==0 && partnerDetails.map((data)=>{
                                  return(
                                    <option key={data?.partner_id} value={data?.partner_id}>{data?.company_name} - {data?.partner_id}</option>
                                  )
                                })
                              }
                            </select>
                          </div>

                        </div>

                      </div>
                    </div>
                    {/* <!-- /.card-body --> */}

                    <div className="card-footer">
                      <button  onClick={register} className="btn btn-primary" disabled={formtype ==='view'}>Submit</button>
                    </div>
                    <br/>
                    <ClientApiTable clientData={clientInfo}/>
                    <br />
                    <TokenTable clientData={clientInfo} />
                  </form>
                </div>
                {/* <!-- /.card -->

            <!-- general form elements --> */}

                {/* <!-- /.card --> */}

                {/* <!-- Input addon --> */}

                {/* <!-- /.card -->
            <!-- Horizontal Form --> */}

                {/* <!-- /.card --> */}

              </div>
              {/* <!--/.col (left) --> */}
              {/* <!-- right column --> */}

              {/* <!--/.col (right) --> */}
            </div>
            {/* <!-- /.row --> */}
          </div>
          {/* <!-- /.container-fluid --> */}
        </section>
      }
      <MDBox p={2} mt="auto" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>

        {(formtype!=='view' && formtype !=='edit' && formtype!=='add') &&
          <MDButton variant="gradient" onClick={() => setFormType('add')} color={sidenavColor}>
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;add Client
          </MDButton>
        }
      </MDBox>
      {
        (formtype!=='view' && formtype !=='edit' && formtype!=='add') && 
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white" style={{ display: "flex", justifyContent: 'space-between' }}>
                  Client Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {clientdetails &&
                  <DataGrid
                    sx={{ padding: '1.5rem' }}
                    rows={row}
                    columns={column}
                    columnVisibilityModel={{
                      id: false,
                    }}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                    }}
                    pageSizeOptions={[5]}
                    // checkboxSelection
                    experimentalFeatures={{ newEditingApi: true }}
                    disableRowSelectionOnClick
                  />}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      }

      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
