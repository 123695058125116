import React from 'react'
import './Error404.css'
// import BasicLayout from "layouts/authentication/components/CoverLayout";
import MDBox from "components/MDBox";
// import Card from "@mui/material/Card";
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { Link } from 'react-router-dom';
const Error404 = () => {
    return (
        <>



            {/* <BasicLayout> */}
            {/* <Card > */}
            {/* <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        mx={0}
                        mt={-3}
                        p={2}
                        mb={1}
                        textAlign="center"
                    > */}

            {/* </MDBox> */}
            {/* </Card> */}
            {/* </BasicLayout> */}
            <PageLayout>
                {/* <DefaultNavbar
                // style={{display:'none'}}
                // action={{
                //   type: "external",
                //   route: "https://creative-tim.com/product/material-dashboard-react",
                //   label: "free download",
                //   color: "dark",
                // }}
                /> */}
                {/* <MDBox
                    position="absolute"
                    width="100%"
                    minHeight="100vh"
                    sx={{
                        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                            image &&
                            `${linearGradient(
                                rgba(gradients.dark.main, 0.6),
                                rgba(gradients.dark.state, 0.6)
                            )}, url(${image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                /> */}
                <MDBox px={1} width="100%" height="100vh" mx="auto">
                    < div className='error_board'>
                        <h1>404 Error Page </h1>
                        <p class="zoom-area">"Oops! Looks like you took a wrong turn. Don't worry, even the best explorers get lost. Let's navigate back together!"</p>
                        <section class="error-container">
                            <span class="four"><span class="screen-reader-text">4</span></span>
                            <span class="zero"><span class="screen-reader-text">0</span></span>
                            <span class="four"><span class="screen-reader-text">4</span></span>
                        </section>
                        <div class="link-container">
                            <Link to="/" class="more-link">Home</Link>
                        </div>
                    </div>
                    {/* <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid> */}
                </MDBox>
                {/* <Footer light /> */}
            </PageLayout>
        </>
    )
}

export default Error404